import {AxiosInstance} from 'axios';
import getLazyQueryHook from './lazyQuery';
import getMutationHook from './mutation';
import getQueryHook from './query';

export interface RequestOptions<DataType> {
  url: string;
  method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
  params?: Record<string, any>;
  data?: DataType;
}

export interface EndpointBuilderOptions {
  globalData?: any;
}

export interface HookBuilder {
  baseQuery: AxiosInstance;
  options?: EndpointBuilderOptions;
}

function getEndpointBuilders(
  baseQuery: AxiosInstance,
  {globalData}: EndpointBuilderOptions,
) {
  return {
    query: getQueryHook({baseQuery}),
    mutation: getMutationHook({baseQuery, options: {globalData}}),
    lazyQuery: getLazyQueryHook({baseQuery, options: {globalData}}),
  };
}

export default getEndpointBuilders;
