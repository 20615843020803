import Cookies from 'js-cookie';
// TODO: remove this file after the login is refactored
// and is not needed to send the csrf-cookie on the header
interface CookiesOptions {
  name: string;
  value?: string;
  expires?: number;
}

export function getCookie(name: string): string | undefined {
  return Cookies.get(name);
}

export function setCookie({name, value = '', expires}: CookiesOptions): void {
  Cookies.set(name, value, {expires});
}

export function deleteCookie({name}: CookiesOptions): void {
  Cookies.remove(name);
}
