import ScreenLoader from 'components/ScreenLoader';
import React, {useLayoutEffect} from 'react';
import {RouterProvider} from 'react-router-dom';
import {router} from 'routes';
import {setupTrackers} from 'setupTrackers';
import './App.module.scss';

function App(): JSX.Element {
  useLayoutEffect(() => {
    setupTrackers();
  }, []);

  return <RouterProvider router={router} fallbackElement={<ScreenLoader />} />;
}

export default App;
