import getEndpointBuilders from '../endpointBuilder/builder';
import {FromHookName, ToHookName} from '../types';
import {capitalize} from '../utils';

type EndpointBuilder = ReturnType<typeof getEndpointBuilders>;

interface InjectEndpointParams {
  endpoints(build: EndpointBuilder): Record<string, (...args: any) => any>;
}

export function getEndpointsInjector(endpointBuilders: EndpointBuilder) {
  return function injectEndpoints<InputParams extends InjectEndpointParams>({
    endpoints,
  }: InputParams) {
    type Endpoints = ReturnType<InputParams['endpoints']>;
    type HookNames = ToHookName<keyof Endpoints>;
    type EndpointHooks = {[name in HookNames]: Endpoints[FromHookName<name>]};

    const customHooks = endpoints(endpointBuilders);
    const endpointHooks: EndpointHooks = {} as any;

    for (const key in customHooks) {
      endpointHooks[`use${capitalize(key)}Query`] = (...args: any) =>
        customHooks[key](...args);
    }

    return endpointHooks;
  };
}
