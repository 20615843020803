import * as Sentry from '@sentry/react';
import letrusAdminAPI from 'APIs/letrusAdminAPI';
import PageLayout from 'components/PageLayout';
import ScreenLoader from 'components/ScreenLoader';
import React from 'react';
import {Outlet} from 'react-router-dom';
import {getCookie} from 'utils/cookies';
import encryptStorage from 'utils/functions/encryptStorage';
import forceLogout from 'utils/functions/forceLogout';
import {User} from 'utils/types/authentication';

function AuthRoute(): JSX.Element {
  const authedUser = encryptStorage.decryptAndRetrieve<User>('BKO-user');
  const isAuthenticated = authedUser?.is_staff || authedUser?.is_superuser;

  if (!isAuthenticated) {
    forceLogout();
    return <ScreenLoader />;
  }

  letrusAdminAPI.injectHeader({'X-CSRFToken': getCookie('csrftoken')});

  Sentry.setUser({
    id: authedUser.id,
    username: authedUser.username,
  });

  return (
    <PageLayout>
      <Outlet />
    </PageLayout>
  );
}

export default AuthRoute;
