import {AxiosRequestConfig} from 'axios';
import {getAxiosInstance} from './axios/axiosConfig';
import getEndpointBuilders from './endpointBuilder/builder';
import {getEndpointsInjector} from './endpointInjector/injector';

interface RequestConfig<Data> extends Omit<AxiosRequestConfig, 'data'> {
  onUnauthorized?(error: Error): void;
  data?: Data;
}

function createAPI<GlobalData>(requestConfig: RequestConfig<GlobalData>) {
  const axiosInstance = getAxiosInstance(requestConfig);
  const endpointBuilders = getEndpointBuilders(axiosInstance, {
    globalData: requestConfig.data,
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401) {
        requestConfig.onUnauthorized?.(error);
      }
      return Promise.reject(error);
    },
  );

  function injectHeader(header: Record<string, unknown>) {
    axiosInstance.defaults.headers.common = {
      ...axiosInstance.defaults.headers.common,
      ...header,
    };
  }

  const injectEndpoints = getEndpointsInjector(endpointBuilders);
  return {injectEndpoints, injectHeader};
}

export default createAPI;
