export function capitalize(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function validateURL(
  url: string,
  queryParams: Record<string, unknown>,
): boolean {
  const hasInvalidRouteParam = url.match(/undefined|null/);

  const hasInvalidQueryParam = Object.values(queryParams).some(
    (value) => typeof value === 'undefined',
  );

  return !hasInvalidRouteParam || !hasInvalidQueryParam;
}
