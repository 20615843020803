import useSWRMutation, {SWRMutationConfiguration} from 'swr/mutation';
import {HookBuilder, RequestOptions} from './builder';

interface QueryConfig<DataType> extends RequestOptions<DataType> {
  config?: SWRMutationConfiguration<any, any, any>;
}

function getLazyQueryHook({baseQuery}: HookBuilder) {
  return <ResponseType>(reqParams: QueryConfig<ResponseType>) => {
    const {trigger, isMutating, ...hookResponse} = useSWRMutation<
      ResponseType,
      any,
      string,
      Record<string, any>
    >(
      reqParams.url,
      (url, arg) =>
        baseQuery(url, {
          ...reqParams,
          method: reqParams.method ?? 'GET',
          params: {...reqParams.params, ...arg},
        }).then((res) => res.data),
      {throwOnError: false, ...reqParams.config},
    );

    return {
      ...hookResponse,
      success: !!hookResponse.data && !hookResponse.error && !isMutating,
      isLoading: isMutating,
      fetch: trigger,
    };
  };
}

export default getLazyQueryHook;
