import useSWRMutation, {SWRMutationConfiguration} from 'swr/mutation';
import {clearObject} from 'utils/functions/clearObject';
import {HookBuilder, RequestOptions} from './builder';

interface QueryConfig<ResponseType, PayloadType>
  extends RequestOptions<ResponseType> {
  config?: SWRMutationConfiguration<any, any, any> & {
    transformPayload?(payload: PayloadType): any;
    shouldBlockRequest?: boolean;
  };
}

interface TriggerParams<PayloadType> {
  data: PayloadType;
  params?: Record<string, any>;
}

function getMutationHook({baseQuery, options}: HookBuilder) {
  return <ResponseType, PayloadType>(
    reqParams: QueryConfig<ResponseType, PayloadType>,
  ) => {
    function buildPayload(triggerData?: TriggerParams<PayloadType>) {
      const payload = clearObject({
        ...reqParams.data,
        ...triggerData?.data,
        ...options?.globalData,
      });

      return reqParams.config?.transformPayload
        ? reqParams.config?.transformPayload?.(payload)
        : payload;
    }

    const {trigger, isMutating, ...hookResponse} = useSWRMutation<
      ResponseType,
      any,
      string,
      TriggerParams<PayloadType> | undefined
    >(
      reqParams.url,
      (url, {arg}) =>
        baseQuery(url, {
          ...reqParams,
          params: {...reqParams.params, ...arg?.params},
          data: buildPayload(arg),
        }).then((res) => res.data),
      {throwOnError: false, ...reqParams.config},
    );

    return {
      ...hookResponse,
      success: !!hookResponse.data && !hookResponse.error && !isMutating,
      isLoading: isMutating,
      send: reqParams.config?.shouldBlockRequest ? () => {} : trigger,
    };
  };
}

export default getMutationHook;
