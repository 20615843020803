export const IS_PROD_ENVIRONMENT =
  !window.location.host?.match(/beta|localhost/);

export const LEARNING_PATH_TYPES = [
  {label: 'EDE', value: 'ede'},
  {label: 'Outra', value: 'other'},
  {label: 'Letrus', value: 'letrus'},
  {label: 'Escola', value: 'school'},
  {label: 'Especial', value: 'special'},
  {label: 'Conhecimento', value: 'knowledge'},
  {label: 'Competências', value: 'competence'},
  {label: 'Diagnóstica', value: 'diagnostics'},
  {label: 'Leitura Mediada', value: 'mediated reading'},
  {
    label: 'Sensibilização e interpretação',
    value: 'sensitization and interpretation',
  },
];
