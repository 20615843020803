import AuthRoute from 'components/AuthRoute';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="*" lazy={() => import('containers/NotFound')} />
      <Route path="login" lazy={() => import('containers/Login')} />

      <Route element={<AuthRoute />}>
        <Route path="" lazy={() => import('containers/Home')} />

        <Route path="usuarios" lazy={() => import('containers/UsersRead')} />
        <Route
          path="turmas"
          lazy={() => import('containers/SchoolGroupsRead')}
        />
        <Route
          path="redacoes"
          lazy={() => import('containers/CompositionsRead')}
        />
        <Route
          path="dashboards/:userRole"
          lazy={() => import('containers/ManagerDashboards')}
        />
        <Route
          path="upload-planilha/:registrationType"
          lazy={() => import('containers/MassiveRegistration')}
        />

        <Route path="templates">
          <Route
            path="atividades"
            lazy={() => import('containers/ActivtiesTemplates')}
          />
          <Route
            path="trilhas"
            lazy={() => import('containers/LearningPathTemplateCRUD')}
          />
        </Route>

        <Route path="trilhas">
          <Route
            path="editar"
            lazy={() => import('containers/LearningPathUpdate')}
          />
          <Route
            path="excluir"
            lazy={() => import('containers/LearningPathDelete')}
          />
          <Route
            path="cadastrar"
            lazy={() => import('containers/LearningPathCreate')}
          />
        </Route>

        <Route path="atividades">
          <Route path="" lazy={() => import('containers/TestsRead')} />
          <Route path="editar" lazy={() => import('containers/TestsUpdate')} />
          <Route
            path="cadastrar"
            lazy={() => import('containers/TestsCreate')}
          />
        </Route>

        <Route path="escolas">
          <Route path="" lazy={() => import('containers/SchoolsRead')} />
          <Route
            path=":schoolId/editar"
            lazy={() => import('containers/SchoolsUpdate')}
          />
        </Route>

        <Route path="contratos">
          <Route path="" lazy={() => import('containers/ContractsRead')} />
          <Route
            path=":contractId/editar"
            lazy={() => import('containers/ContractsUpdate')}
          />
        </Route>

        <Route path="notificacoes/:userRole">
          <Route
            path="enviar"
            lazy={() => import('containers/NotificationsSend')}
          />
          <Route
            path="criar-grupo"
            lazy={() => import('containers/NotificationGroupCreate')}
          />
        </Route>
      </Route>
    </Route>,
  ),
);
