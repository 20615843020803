import {useMemo, useState} from 'react';
import useSWR, {SWRConfiguration} from 'swr';
import {clearObject} from 'utils/functions/clearObject';
import {HookBuilder, RequestOptions} from './builder';

interface QueryConfig<DataType> extends RequestOptions<DataType> {
  config?: SWRConfiguration<any, any, any>;
}

function getQueryHook({baseQuery}: HookBuilder) {
  return <ResponseType>(initialParams: QueryConfig<ResponseType>) => {
    const [queryParams, setQueryParams] = useState<any>({});
    const [reFetchConfig, setReFetchConfig] = useState<SWRConfiguration<any>>(
      {},
    );
    const requestParams = {...initialParams.params, ...queryParams};
    const hasInvalidParam = Object.values(requestParams).some(
      (value) => value === null || value === undefined,
    );

    const isInvalidURL = initialParams.url.match(/undefined|null/);

    const shouldBlockFetch = hasInvalidParam || isInvalidURL;

    const hookResponse = useSWR<ResponseType>(
      shouldBlockFetch ? null : [initialParams.url, requestParams],
      ([url, params]) =>
        baseQuery(url, {
          params,
          method: initialParams.method ?? 'GET',
        }).then((res) => res.data),
      {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        keepPreviousData: true,
        ...initialParams.config,
        ...reFetchConfig,
      },
    );

    const reFetch = useMemo(
      () =>
        (
          queryParams: Record<string, any> = {},
          config: SWRConfiguration<any> = {},
        ) => {
          if (!Object.keys(queryParams).length && !Object.keys(config).length) {
            hookResponse.mutate();
          }
          setQueryParams(clearObject(queryParams));
          setReFetchConfig(clearObject(config));
        },
      [],
    );

    return {
      ...hookResponse,
      success:
        !!hookResponse.data && !hookResponse.error && !hookResponse.isLoading,
      reFetch,
      isLoading: hookResponse.isLoading || hookResponse.isValidating,
    };
  };
}
export default getQueryHook;
